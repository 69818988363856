<template>
  <div>
    <SidebarBulk />
    <div class="mt-8 pt-5">
      <v-toolbar flat style="padding: 0px;">
        <BackComponent class="mt-1" style="margin-left: -10px; margin-right: 10px" />
        <v-spacer></v-spacer>
        <v-row style="padding-left: 0px;">
          <v-col>
            <v-select
              v-model="selectedDate"
              @change="changeDates()"
              :items="bulkSelectDate"
              item-text="date"
              item-value="slug_group"
              style="color: #EB5757"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-toolbar>
    </div>
    <div v-if="isLoading" class="text-center" style="margin-top: 5%">
      <SkeletonDetail />
    </div>
    <v-container v-else class="mt-3 mb-9">
      <!-- <div class="text-product">
        <h3 style="text-transform: capitalize;">{{ bulkDetail.item_name }}</h3>
      </div> -->
      <v-row>
        <v-col>
          <h4 style="text-transform: none;">{{ bulkDetail.item_name }}</h4>
        </v-col>
        <v-col>
          <p class="d-flex justify-end text--primary px-2 py-1" style="font-size: 12px">
            {{ $t("message.stock") }}: {{ selectedDate.stock }}
            </p>
        </v-col>
      </v-row>
      <div>
        <v-icon size="20">
          mdi-store
        </v-icon>
        {{ storeName.store_name }}
      </div>
      <div style="margin-bottom:25px; margin-top: 15px">
        <v-row>
          <v-col>
            <div class="row-image-detail">
              <v-carousel
                v-model="model"
                height="200"
                contain
                transition="fade-transition"
                reverse-transition="fade-transition"
                hide-delimiter-background
                hide-delimiters
                style="position: relative"
              >
                <v-carousel-item
                  v-for="(img, i) in bulkDetail.images"
                  :key="i"
                  contain
                  :src="img.image_url"
                ></v-carousel-item>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="white"
                  style="position: absolute; bottom: 8%; left: 2%"
                >
                  <v-icon dark color="grey">
                    mdi-heart
                  </v-icon>
                </v-btn>
              </v-carousel>
            </div>
            <!-- <div class="text-price text_color">
              <span class="pe-2" style="font-size: 75%;">{{ $t("message.label-price-tax") }}</span>
              <span class="pe-1">{{ formatDecWithoutComa(bulkDetail.price_with_tax) }}</span>
              <span class="" style="font-size: 75%;">円</span>
            </div> -->
            <!-- <div class="grey--text">
               {{ $t("message.label-price-wo-tax") }}:
              <span class="">¥{{ formatDecWithoutComa(bulkDetail.price) }}</span>
            </div> -->
            <div class="d-flex flex-row justify-end align-end pt-3 pb-3" style="color: #ff0090;">
              <span style="margin-right: 10px;">
                <span style="font-size:75%;vertical-align: baseline;">
                  {{ $t("message.label-price-tax") }}
                </span>
                <span>
                  {{formatDecWithoutComa(bulkDetail.price_with_tax)}}
                </span>
                <span style="font-size:75%;vertical-align: baseline;"> 円</span>
              </span>
            </div>
            <v-divider style="color: blue;"></v-divider>
            <div class="text-merchant" style="cursor:pointer">
              <v-icon size="30">
                mdi-store
              </v-icon>
              {{ merchantName }}
            </div>
            <v-divider style="color: blue;"></v-divider>
            <div class="text-description">
              <div style="font-size:20; font-weight:500;margin-bottom:9px">
                {{ $t("message.description") }}
              </div>
              <div style="font-size:18;">
                {{ bulkDetail.description }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <CashierBulk :isSlug="this.selectSlug" />
  </div>
</template>

<script>
import SidebarBulk from "../../components/bulk/SidebarBulk.vue";
import SkeletonDetail from "../../components/skeleton/SkeletonDetail.vue";
import CashierBulk from "../../components/bulk/CashierBulk.vue"
import Back from "../../components/Back.vue";
import { mdiArrowLeft } from "@mdi/js";
import { moneyFormat } from "../../helpers";
import { mapState } from "vuex";
import formatDecWithoutComa from '../../helpers/formatDecWithoutComa'

export default {
  name: "BulkDetail",
  components: {
    SidebarBulk,
    BackComponent: Back,
    SkeletonDetail,
    CashierBulk,
  },
  data() {
    return {
      isLoading: false,
      model: 0,
      stock: "",
      selectedDate: [],
      selectSlug: "",
      notFound: require("../../assets/image-not-found.png"),
      iconArrowLeft: mdiArrowLeft,
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  created() {
    this.dispatchBulkDetail();
  },
  computed: {
    ...mapState({
      storeName: (state) => state.bulk_module.storeName,
      merchantName: (state) => state.bulk_module.merchantName,
      bulkDetail: (state) => state.bulk_module.bulkDetailResult,
      bulkSelectDate: (state) => state.bulk_module.bulkSelectDate,
    }),
  },
  methods: {
    moneyFormat(money) {
      return moneyFormat(money);
    },
    formatDecWithoutComa(money) {
      return formatDecWithoutComa(money)
    },
    async dispatchBulkDetail() {
      let slug = this.$router.history.current.params.slug;
      this.isLoading = true;
      await this.$store.dispatch("bulk_module/bulkDetail", slug);

      this.isLoading = false;
      let dispatchDetail = this.$store.state.bulk_module.bulkDetailHeader;
      this.selectedDate = dispatchDetail.current_date;
      this.selectSlug = this.selectedDate.slug_group;
    },
    changeDates() {
      this.selectSlug = this.selectedDate;
    },
  },
};
</script>
<style scoped>
.row-header {
  background-color: white;
  height: 36px;
  width: 100%;
  margin-top: -15px;
}

.text-store {
  font-size: 18px;
  font-weight: 400;
  margin-top: 3px;
}
.text-fee {
  font-size: 18px;
  font-weight: 500;
}

.text-price {
  font-size: 16px;
  font-weight: 500;
  margin-top: 19.6px;
}

.text-product {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 3px;
}

.text-merchant {
  font-size: 20px;
  font-weight: 500;
  margin: 21.6px 0px 21.6px 0px;
}

.text-description {
  margin-bottom: 65px;
  margin-top: 2%;
}

/* .row-footer {
  position: fixed;
  bottom: 0;
  right: 3;
  width: 100%;
  color: white;
  font-size: 12px;
  font-weight: 400;
  background-color: #3f51b5;
} */
</style>
