<template>
  <div v-if="isLogin>0" :class="isBulkDetail ? 'row-footer-detail' : 'row-footer'">
    <v-card elevation="3" tile>
      <v-row>
        <v-col cols="12" v-if="isBulkDetail" class="me-8 text-center" >
          <div>
            <v-btn
              color="#ff0090"
              class="white--text text-center"
              style="width: 84%; "
              small
              @click.prevent="$router.push(`/bulk/${isSlug}/group-list`)"
              :disabled="is_stock.stock == 0"
            >
              <v-icon left dark class="mb-1">
                mdi-account-group
              </v-icon>
              {{ $t("message.group-selection") }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="2">
          <div class="ps-3">
            <div
              class=""
              style="background-color: black; width: fit-content; border-radius: 50%;"
            >
              <v-icon class="p-5" style="padding: 7px;" size="21" color="#ff0090">
                mdi-cart
              </v-icon>
            </div>
          </div>
        </v-col>
        <v-col style="padding-left: 0px; padding-right: 0px">
          <div>
            <p class="black--text" small>お会計</p>
            <p class="black--text">Cashier</p>
          </div>
        </v-col>
        <v-col cols="3" style="padding-left: 0px; padding-right: 0px">
          <div>
            <p class="black--text" style="font-size: 10px">
              {{ $t("message.total-bill") }}: {{moneyFormat(totalOrderPrice)}}
            </p>
            <p class="black--text" style="font-size: 10px">
              {{ $t("message.total-spending") }}: {{totalCart}}
            </p>
          </div>
        </v-col>
        <v-col class="me-2" style="padding-left: 0px;">
          <v-btn
            depressed
            small
            color="black"
            class="white--text text-lowercase"
            style="padding-left: 10px; padding-right: 5px;"
            @click="$router.push('/carts')"
          >
            {{ $t("message.continue-to-order") }}
            <v-icon right dark>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import moneyFormat from "../../helpers/moneyFormat"
import Swal from "sweetalert2";
export default {
  data() {
    return {
      isBulkDetail: false,
      message: {},
      selectSlug: "",
      isLoading: false,
      is_stock: ""
    };
  },
  props: {
    isSlug: String
  },
  created() {
    this.getRouteName();
    this.getALlShopData()
    this.dispatchBulkDetail()
  },
  computed: {
    resultItems() {
      return this.$store.state.itemDetail_module.itemDetailResult;
    },
    // current_stock() {
    //   return this.$store.state.bulk_module.bulkDetailHeader.current_date
    // },
    totalCart(){
      // return this.$store.state.bulk_module.badgeBulkLength
      return this.$store.state.carts_module.badgeLength
    },
    totalOrderPrice(){
      // return this.$store.state.bulk_module.totalItemPriceBulk
      return this.$store.state.carts_module.totalItemPrice
    },
    isLogin() {
      return $cookies.get("isLogin");
    },
  },
  methods: {
    async getALlShopData(){
      if (this.isLogin > 0) {
        await this.$store.dispatch("carts_module/fetchCarts")
      }
    },  
    moneyFormat(money) {
      return moneyFormat(money);
    },
    getRouteName() {
      let page = this.$route.name;
      if (page === "BulkDetail") this.isBulkDetail = true;
      else this.isBulkDetail = false;
    },
    async dispatchBulkDetail() {
      let slug = this.$router.history.current.params.slug;
      this.isLoading = true;
      await this.$store.dispatch("bulk_module/bulkDetail", slug);

      this.isLoading = false;
      let dispatchDetail = this.$store.state.bulk_module.bulkDetailHeader;
      this.is_stock = dispatchDetail.current_date;
      // this.selectSlug = this.selectedDate.slug_group;
    },
    addCart(id, store_id) {
      let cookies = $cookies.get("isLogin");
      if (cookies == 0) {
        Swal.fire({
          icon: "error",
          text: this.$t("message.title-err-login-first"),
        });
      } else {
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: id, 
          storeId: store_id ,
          message: {
            title: this.message.title,
          },
        });
      }
    },
  },
};
</script>

<style>
.row-footer {
  margin-top: 30px;
  position: fixed;
  height: 60px;
  bottom: 0em;
  width: 100%;
  color: white;
  font-size: 12px;
  font-weight: 400;
  /* background-color: #3f51b5; */
}
.row-footer-detail {
  margin-top: 30px;
  position: fixed;
  height: 60px;
  bottom: 4.5em;
  width: 100%;
  color: white;
  font-size: 12px;
  font-weight: 400;
  /* background-color: #3f51b5; */
}
</style>
